﻿import { Collapse } from "bootstrap";

export default function () {

  function alert() {
    const alBtn = document.body.querySelector("#close-alert");

    if (!alBtn) return;

    const cookie = alBtn.dataset.cookie ?? "pb";

    if (!decodeURIComponent(document.cookie).includes(cookie+"=")) {
      const alertEl = alBtn.closest('.alert');
      alertEl.classList.remove("d-none");

      alBtn.addEventListener("click",
        e => {
          const d = new Date();
          const days = 7;
          d.setTime(d.getTime() + (days * 24 * 60 * 60 * 1000));
          let expires = "expires=" + d.toUTCString();
          document.cookie = cookie + "=true;" + expires + ";path=/";

        })
    }
  }
  alert();
};